/* eslint-disable max-len */
const lt = {
  translation: {
    homepage: {
      readNewsitem: 'Skaityti naujienas',
      seeAll: 'Visos naujienos',
      back: 'Atgal',
      latestNews: 'Naujausios žinios',
      quickLinks: 'Greitosios nuorodos',
    },
    navigation: {
      more: 'Daugiau',
      previous: 'Atgal',
      next: 'Kitas',
      toggle: 'Perjungti',
      backToOverview: 'Atgal į apžvalgą',
      home: 'Pradžia',
      news: 'Naujienos',
      workplace: 'Gamykla',
      hr: 'HR',
    },
    404: {
      oops: 'Ups!',
      notFoundTitle: 'Puslapis nerastas',
      notFoundText:
        'Ieškomas puslapis nerastas. Grįžk į <homeLink>pagrindinį langą</homeLink> ir bandyk iš naujo.',
    },
    storyblok: {
      errorTitle: 'Klaida',
      errorMessage: 'Pabandyk vėliau',
      noPostsFound: 'Įrašas nerastas',
    },
    news: {
      title: 'Naujienos',
      subTitle: 'Ieškoti naujausių žinių',
      likes_one: '{{count}} like',
      likes_few: '{{count}} likes',
      likes_other: '{{count}} likes',
      comments: 'komentarai',
      relatedPostsTitle: 'Susijusios naujienos',
      commentsTitle: 'Komentarai',
      search: 'Įrašyk, ko ieškai',
    },
    settings: {
      title: 'Nustatymai',
      subTitle: 'Čia gali pasirinkti savo nustatymus.',
      language: 'Kalba',
      notifications: 'Pranešimai',
      en: 'English language',
      lt: 'Lietuvių kalba',
      enableNotifications: 'Įjungti pranešimus',
    },
    login: {
      enterUsername: 'Vartotojas',
      enterPassword: 'Slaptažodis',
      signInButton: 'Prisijungti',
      signInTitle: 'Prisijungti prie WeMove',
      error:
        'Nepavyko prisijungti. Patikrinkite prisijungimo duomenis ir bandykite dar kartą',
    },
  },
};

export default lt;
